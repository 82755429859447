.b_top_idx {
  overflow: hidden;
  padding: 6px 0 30px 0;
}

.b_various_links {
  float: left;
  width: 210px;
  padding: 0px 15px 0px 15px;
}

.b_various_links h2 {
  padding: 8px 0 14px 0;
}

.b_various_links .row_link {
  font-size: 12px;
  padding: 0 0 5px 0;
}

.b_various_links .row_link .name {
  display: block;
  font-size: 12px;
  font-weight: bold;
  padding: 0 0 3px 0;
}

.b_various_links .row_link .name a {
  color: #654109;
}

.b_top_title {
  font-size: 18px;
  margin: 0;
  padding: 8px 0 14px 0;
  color: #654109;
  font-weight: normal;
}

.t_search {
  padding: 0 0 10px 0;
}

.t_search .input {
  height: 23px;
  padding: 0 6px;
  font-size: 12px;
  color: #000;
  border: 1px solid #a9a9a9;
  width: 100%;
  box-sizing: border-box;
}

.search_form_main .submit {
  position: absolute;
  right: 2px;
  top: 2px;
}

.search_form_main {
  position: relative;
}

.search_form_main .submit {
  position: absolute;
  right: 2px;
  top: 2px;
}

.search_form_main .submit .button {
  cursor: pointer;
  display: block;
  box-sizing: border-box;
  height: 19px;
  width: 19px;
  border: none;
  outline: none;
  background: url('/images/search.svg') no-repeat center;
  background-size: cover;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.search_form_main .submit .button:hover {
  opacity: 1;
}

/* Top 5 */
.b_top_5 {
  float: left;
  width: 276px;
  margin-right: 12px;
  background: rgba(101, 65, 9, 0.4);
  border-radius: 10px;
  min-height: 280px;
}

.b_top_5 a {
  color: #fbf4d0;
}

.b_top_5 .title {
  background: url('/images/i_book.png') no-repeat 10px 11px rgba(101, 65, 9, 0.5);
  height: 34px;
  line-height: 34px;
  color: #fbf4d0;
  font-size: 12px;
  font-weight: bold;
  padding-left: 35px;
  margin: 0 0 10px 0;
  border-radius: 10px 10px 0 0;
}

.b_top_5 .book_names {
  margin: 0 0 21px 30px;
  color: #fbf4d0;
  font-size: 12px;
  font-weight: bold;
  list-style-type: number;
}

.b_top_5 .book_names li {
  padding: 0 0 18px 5px;
}

.b_top_5 .book_names li a {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

.b_top_5 .t_book_popular {
  background: url('/images/i_rating.png') no-repeat 0 11px;
  font-size: 12px;
  font-weight: bold;
  color: #fbf4d0;
  border-top: 1px solid rgba(101, 65, 9, 0.5);
  margin: 0 10px;
  padding: 0 0 0 25px;
  height: 34px;
  line-height: 34px;
}

.b_recommend {
  float: left;
  width: 216px;
  background: rgba(101, 65, 9, 0.3);
  border-radius: 10px;
  min-height: 280px;
}

.b_recommend img {
  width: 120px;
  height: auto;
}

.b_recommend .title {
  background: url('/images/i_favorite.png') no-repeat 10px 11px rgba(101, 65, 9, 0.6);
  height: 34px;
  line-height: 34px;
  color: #fbf4d0;
  font-size: 12px;
  font-weight: bold;
  padding-left: 35px;
  margin: 0 0 10px 0;
  border-radius: 10px 10px 0 0;
}

.b_recommend .book {
  text-align: center;
  font-size: 12px;
  padding: 0 0 10px 0;
}

.b_recommend .book .img {
  display: block;
  padding: 0 0 8px 0;
}

.b_recommend .book .name {
  display: block;
  font-weight: bold;
  padding: 0 0 10px 0;
}

.b_recommend .book a {
  color: #654109;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

.b_recommend .t_discuss_forum {
  font-size: 12px;
  display: inline-block;
  vertical-align: top;
  background: #efe8d8;
  border-radius: 6px;
  padding: 2px 7px;
}

@media screen and (max-width: 990px) {
  .b_various_links {
    float: none;
    width: auto;
    min-height: auto;
    margin: 0 0 30px 0;
    padding: 0;
  }

  .b_various_links .row_link {
    padding: 0 0 15px 0;
    font-size: 13px;
  }

  .b_top_5 {
    float: none;
    width: auto;
    min-height: auto;
    margin: 0 0 30px 0;
  }

  .b_recommend {
    float: none;
    width: auto;
    min-height: auto;
    margin: 0;
  }
}
